<template>

      <v-expansion-panels class="mt-2 card" flat>
      <v-expansion-panel class="card">
        <v-expansion-panel-header expand-icon="mdi-menu-down" class="px-3">
          <span class="font  ">تعیین بازه قیمت</span>
        </v-expansion-panel-header>   
        <v-expansion-panel-content>  
          <v-row class="d-flex align-center pt-2">
            <v-range-slider v-model="range" :max="filter_cost.max"
            :min="filter_cost.min"
            hide-details></v-range-slider>
          </v-row>
          <v-row class="d-flex justify-space-around my-2 align-center">
          <div class="d-flex flex-column align-center justify-center"> 
            <span class="medium-font ">از</span>
            <span class="medium-font "  v-text="min_price" @change="$set(range, 0, $event)"></span>
            <!-- <span class="medium-font subheaderFont--text">تومان</span> -->
          </div>
          <v-divider vertical></v-divider>
          <div class="d-flex flex-column align-center justify-center" >
            <span class="medium-font ">تا</span>
            <span class="medium-font " v-text="max_price" @change="$set(range, 1, $event)"></span>
            <!-- <span class="medium-font subheaderFont--text">تومان</span> -->
          </div> 
          </v-row> 
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import { changeTypePrice_format, changeTypePrice_range } from '@/models/changeType'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      filters: state => state.search.filter_search_filds
    }),
    filter_cost () {
      let cost = {}
      for (const filter of this.filters) {
        if (filter.title == 'قیمت') {
          cost = filter
        }
      }
      return cost
    }
  },
  mounted () {
    this.range = [this.filter_cost.min, this.filter_cost.max]
  },
  watch: {
    range: {
      handler: function () {
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
        } else {
          filters = {}
        }
        filters.max = this.range[1]
        filters.min = this.range[0]
        localStorage.setItem('filter_search', JSON.stringify(filters))
        this.min_price = changeTypePrice_format({
          price: this.range[0],
          Tax_price: '0'
        })
        this.max_price = changeTypePrice_format({
          price: this.range[1],
          Tax_price: '0'
        })
      }
    }
  },
  data: () => ({
    ex3: {  val: 50 },
    range: [],
    max_price:'',
    min_price: ''
  })
}
</script>